/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const auth = require('./src/utils/auth');

const DOL_USER_ID_KEY = 'https://api.dolenglish.com/dolUserId';

exports.onRouteUpdate = () => {
  const authData = auth.getAuth();

  if (authData) {
    const dolUserId = (authData.idTokenPayload && authData.idTokenPayload[DOL_USER_ID_KEY]) || null;

    if (!dolUserId) return;

    setTimeout(() => {
      window.dataLayer.push({ dolUserId });
    }, 50);
  }
};
