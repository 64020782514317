import { Auth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from 'jwt-decode';
import { getRoles, getScopes } from './tokenUtils';
import { AUTH0 } from '../constants';

const KEY_APP_METADATA = 'https://api.dolenglish.com/app_metadata';

export const AUTH_KEY = 'dolonline.auth';
const keyPrefix = '@@auth0spajs@@';
const audience = 'default';
const scope = 'openid profile email offline_access';

const webAuth = new Auth0Client({
  domain: AUTH0.DOMAIN,
  client_id: AUTH0.CLIENT_ID,
  redirect_uri: AUTH0.REDIRECT_URL,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
});

export const isBrowser = () => typeof window !== 'undefined';

export async function isLogged() {
  // Logic to check logged for old auth0 flow and PKCE flow
  return Boolean(JSON.parse(localStorage.getItem(AUTH_KEY))) && webAuth.isAuthenticated();
}

export function saveAuth(auth) {
  if (!isBrowser()) {
    return;
  }

  localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
}

export const isStudent = () => {
  const authData = JSON.parse(localStorage.getItem(AUTH_KEY));
  if (authData && authData.accessToken) {
    const roles = getRoles(authData.accessToken);
    return roles.length === 1 && roles[0].trim().toLowerCase() === 'student';
  }

  return false;
};

export function getAuth() {
  if (!isBrowser()) {
    return null;
  }

  try {
    const authData = JSON.parse(localStorage.getItem(`${keyPrefix}::${AUTH0.CLIENT_ID}::${audience}::${scope}`));
    const data = authData?.body;
    if (data) {
      return {
        accessToken: data.access_token,
        expiresIn: data.expires_in,
        idToken: data.id_token,
        tokenType: data.token_type,
        scope: getScopes(data.access_token),
      };
    }
    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Oops! Fail to retrieve auth data from local storage', error);
    return null;
  }
}

export async function getAccessToken() {
  return webAuth.getTokenSilently().then(token => {
    const authFromCustomLocalCache = JSON.parse(localStorage.getItem(AUTH_KEY));
    const auth = getAuth();
    saveAuth({
      ...authFromCustomLocalCache,
      ...auth,
    });
    return token;
  });
}

function decodeToken(token) {
  try {
    return jwtDecode(token);
  } catch (error) {
    throw new Error('Failed to parse token with error: ', error);
  }
}

export function getAppMetadata(token) {
  try {
    const decoded = decodeToken(token || getAccessToken);
    return decoded[KEY_APP_METADATA] || {};
  } catch (error) {
    return {};
  }
}

export function removeAuth() {
  if (!isBrowser()) {
    return;
  }

  localStorage.removeItem(AUTH_KEY);
}

export async function getAuthFromHash() {
  return webAuth.handleRedirectCallback();
}

export async function getUserInfo() {
  const user = await webAuth.getUser();
  const accessToken = await getAccessToken();
  user.roles = getRoles(accessToken);
  return user;
}

export async function login(provider) {
  await webAuth.loginWithRedirect({
    connection: provider,
    redirect_uri: AUTH0.REDIRECT_URL,
  });
}

export async function logout() {
  if (await webAuth.isAuthenticated()) {
    webAuth.logout({
      returnTo: AUTH0.LOGOUT_REDIRECT_URL,
    });
    removeAuth();
  }
}
