// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-callback-index-js": () => import("./../../../src/pages/callback/index.js" /* webpackChunkName: "component---src-pages-callback-index-js" */),
  "component---src-pages-gmat-online-courses-js": () => import("./../../../src/pages/gmat-online-courses.js" /* webpackChunkName: "component---src-pages-gmat-online-courses-js" */),
  "component---src-pages-gre-online-courses-js": () => import("./../../../src/pages/gre-online-courses.js" /* webpackChunkName: "component---src-pages-gre-online-courses-js" */),
  "component---src-pages-ielts-essay-corrections-js": () => import("./../../../src/pages/ielts-essay-corrections.js" /* webpackChunkName: "component---src-pages-ielts-essay-corrections-js" */),
  "component---src-pages-ielts-online-courses-js": () => import("./../../../src/pages/ielts-online-courses.js" /* webpackChunkName: "component---src-pages-ielts-online-courses-js" */),
  "component---src-pages-ielts-online-tests-js": () => import("./../../../src/pages/ielts-online-tests.js" /* webpackChunkName: "component---src-pages-ielts-online-tests-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-popup-callback-index-js": () => import("./../../../src/pages/popup-callback/index.js" /* webpackChunkName: "component---src-pages-popup-callback-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-pricing-order-detail-js": () => import("./../../../src/pages/pricing/order-detail.js" /* webpackChunkName: "component---src-pages-pricing-order-detail-js" */),
  "component---src-pages-pricing-payment-method-js": () => import("./../../../src/pages/pricing/payment-method.js" /* webpackChunkName: "component---src-pages-pricing-payment-method-js" */),
  "component---src-pages-pricing-result-onepay-international-js": () => import("./../../../src/pages/pricing/result/onepay-international.js" /* webpackChunkName: "component---src-pages-pricing-result-onepay-international-js" */),
  "component---src-pages-pricing-result-onepay-js": () => import("./../../../src/pages/pricing/result/onepay.js" /* webpackChunkName: "component---src-pages-pricing-result-onepay-js" */),
  "component---src-pages-pricing-term-and-policy-js": () => import("./../../../src/pages/pricing/term-and-policy.js" /* webpackChunkName: "component---src-pages-pricing-term-and-policy-js" */),
  "component---src-pages-pronunciation-checker-js": () => import("./../../../src/pages/pronunciation-checker.js" /* webpackChunkName: "component---src-pages-pronunciation-checker-js" */),
  "component---src-pages-sat-online-courses-js": () => import("./../../../src/pages/sat-online-courses.js" /* webpackChunkName: "component---src-pages-sat-online-courses-js" */),
  "component---src-pages-vocab-builder-js": () => import("./../../../src/pages/vocab-builder.js" /* webpackChunkName: "component---src-pages-vocab-builder-js" */)
}

