import jwtDecode from 'jwt-decode';

const KEY_USER_AUTH_DATA = 'https://api.dolenglish.com/user_authorization';

const decodeToken = token => {
  try {
    return jwtDecode(token);
  } catch (error) {
    throw new Error('Failed to parse token with error: ', error);
  }
};

export const getRoles = token => {
  try {
    const decoded = decodeToken(token);
    return decoded[KEY_USER_AUTH_DATA].roles || [];
  } catch (error) {
    return [];
  }
};

export const getScopes = token => {
  try {
    const decoded = decodeToken(token);
    return decoded.scope || [];
  } catch (error) {
    return [];
  }
};
